import Library from '../utility/Library';
import Status from './Status';

const statuses = Library.statuses;

export default class Entity {

	block = 0;
	statuses = [];

	get img () { return this.model.img }

	get power () { return this.getStatusValue(1); }

	get defense () { return this.getStatusValue(2); }

	hasStatus (status) {

		return this.statuses.filter(s => s.model.key === status).length > 0;
	}

	getStatusValue (status) {

		let statuses = this.statuses.filter(s => s.model.key === status);
		return statuses.length > 0 ? statuses[0].value : 0;
	}

	modifyStatusApply (amt, key) {

		let additives = [], multiplicatives = [];
		this.statuses.forEach(status => {
			if (!status.model.modifiers)
				return;
			status.model.modifiers.filter(modifier => modifier.stat === "status" && modifier.status === key).forEach(modifier => {
				switch (modifier.mode) {
				case "additive": additives.push([status, modifier]); break;
				case "multiplicative": multiplicatives.push([status, modifier]); break;
				default: break;
				}
			})
		})

		additives.forEach(status => amt += status[0].value * status[1].value);
		multiplicatives.forEach(status => amt *= status[1].value);

		return amt;
	}

	modifyDamageOut (dmg) {

		let additives = [], multiplicatives = [];
		this.statuses.forEach(status => {
			if (!status.model.modifiers)
				return;
			status.model.modifiers.filter(modifier => modifier.stat === "damageout").forEach(modifier => {
				switch (modifier.mode) {
				case "additive": additives.push([status, modifier]); break;
				case "multiplicative": multiplicatives.push([status, modifier]); break;
				default: break;
				}
			})
		})

		additives.forEach(status => dmg += status[0].value * status[1].value);
		multiplicatives.forEach(status => dmg *= status[1].value);

		return Math.max(0, Math.ceil(dmg));
	}

	modifyDamageIn (dmg, src) {

		dmg = src.modifyDamageOut(dmg);

		let additives = [], multiplicatives = [];
		this.statuses.forEach(status => {
			if (!status.model.modifiers)
				return;
			status.model.modifiers.filter(modifier => modifier.stat === "damagein").forEach(modifier => {
				switch (modifier.mode) {
				case "additive": additives.push([status, modifier]); break;
				case "multiplicative": multiplicatives.push([status, modifier]); break;
				default: break;
				}
			})
		})

		additives.forEach(status => dmg += status[0].value * status[1].value);
		multiplicatives.forEach(status => dmg *= status[1].value);

		return Math.ceil(dmg);
	}

	modifyBlock (blk) {

		let additives = [], multiplicatives = [];
		this.statuses.forEach(status => {
			if (!status.model.modifiers)
				return;
			status.model.modifiers.filter(modifier => modifier.stat === "block").forEach(modifier => {
				switch (modifier.mode) {
				case "additive": additives.push([status, modifier]); break;
				case "multiplicative": multiplicatives.push([status, modifier]); break;
				default: break;
				}
			})
		})

		additives.forEach(status => blk += status[0].value * status[1].value);
		multiplicatives.forEach(status => blk *= status[0].value);

		return Math.max(0, Math.ceil(blk));
	}

	damage (dmg, src, direct=true) {

		if (!dmg || dmg < 0)
			dmg = 0;

		this.master.notify('damage.before', this, src, dmg, direct);

		if (this.block) {
			let shieldbreaker = src && src.hasStatus(25);
			let blockdmg = Math.min(this.block, (shieldbreaker ? 2 : 1) * dmg);
			dmg -= Math.ceil(blockdmg / (shieldbreaker ? 2 : 1));
			this.block -= blockdmg;
			if (!dmg)
				this.master.notify('blockdamage', this, src, blockdmg);
			this.master.notify('blockloss', this, src, blockdmg);
		}

		if (dmg) {
			let assassin = src && src.hasStatus(26);
			if (assassin)
				dmg *= 2;
			dmg = Math.min(this.hp, dmg);
			this.loseLife(dmg, src);
		}

		this.master.notify('damage', this, src, dmg, direct);

		if (this.hp <= 0)
			this.die();

		return { dmg }
	}

	heal (amt) {

		amt = Math.min(amt, this.maxhp - this.hp);
		
		if (!amt || amt < 0)
			return;

		this.hp += amt;
		this.master.notify('lifegain', this, amt);
	}

	loseLife (amt, src) {

		if (!amt || amt < 0)
			return;

		this.hp -= amt;
		this.master.notify('lifeloss', this, src, amt);

		if (this.hp <= 0)
			this.die();
	}

	gainblock (amt) {

		if (!amt || amt < 0)
			return;

		this.block = (this.block || 0) + amt;
		this.master.notify('blockgain', this, amt);
	}

	loseblock (blk) {

		if (!blk || blk < 0)
			return;

		this.block -= blk;
		this.master.notify('blockloss', this, blk);
	}

	die () {

		this.statuses.forEach(status => {
			if (!status.model.postmortem)
				status.dissipate()
		});
	}

	apply (key, value, silent=false) {

		let existing = this.statuses.filter(status => status.model.key === key);
		if (value > 0)
			value = this.modifyStatusApply(value, key);
		if (value === 0)
			return;
		if (existing.length > 0)
			existing[0].add(value);
		else
			this.statuses.push(new Status(this.master, key, this, value));
		if (!silent)
			this.master.notify('applystatus', this, key, value);
	}

	update () {

		if (!this.hasStatus(17))
			this.block = this.hasStatus(23) ? Math.floor((this.block || 0) / 2) : 0;
		this.statuses.forEach(status => status.update());
	}

	endturnUpdate () {

		this.statuses.forEach(status => status.endturnUpdate());
	}
}