import Bloc from './Bloc.js';
import Types from './Types.js';
import Library from '../../utility/Library.js';

export default class CheckStatus extends Bloc {

	constructor (src, ctx) {

		super("checkstatus", src, ctx);
		this.f = (src, ins) => {
			let status = Library.getStatus(ins[0]);
			if (!status)
				return [false];
			return [ins[1] === "" || ins[1] === status.type];
		}
		this.types = [Types.int, Types.statustype];
	}
}