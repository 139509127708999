import React, { Component } from 'react';
import './Options.css';
import Lightbox from '../utility/Lightbox';
import MainButton from '../buttons/MainButton';
import User from '../../User';

import { read, getLanguage, setLanguage } from '../../TextManager';
import { getVolume, setVolume } from '../../SoundManager';

export default class Options extends Component {

  state = {
    status: null,
    isFullscreen: !!document.fullscreenElement // Detect initial fullscreen state
  }

  changeLanguage(language) {
    if (getLanguage() !== language) {
      setLanguage(language);
      window.location.reload(false);
    }
  }

  toggleFullscreen = () => {
    // Enter or exit fullscreen based on current state
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().then(() => {
        this.setState({ isFullscreen: true });
      }).catch(err => {
        console.error('Error attempting to enter fullscreen mode:', err);
      });
    } else {
      document.exitFullscreen().then(() => {
        this.setState({ isFullscreen: false });
      }).catch(err => {
        console.error('Error attempting to exit fullscreen mode:', err);
      });
    }
  }

  componentDidMount() {
    // Listen to fullscreen changes
    document.addEventListener('fullscreenchange', this.handleFullscreenChange);
  }

  componentWillUnmount() {
    // Clean up the event listener
    document.removeEventListener('fullscreenchange', this.handleFullscreenChange);
  }

  handleFullscreenChange = () => {
    this.setState({ isFullscreen: !!document.fullscreenElement });
  }

  render() {

    let language = getLanguage();

    let music = getVolume('music') * 10;
    let sfx = getVolume('sfx') * 10;

    let fastmode = localStorage.getItem("options.fastmode");
    let growthsequences = localStorage.getItem("options.growthsequences") || 'fast';

    return (
      <div className={"nav-item nav-options" + (this.props.concede ? " game-options" : "")}>
        <Lightbox className="small" open={this.state.status === "options"} onClose={() => this.setState({ status: null })}>
          <div className="options-box">
            <h1>{read('menu/options')}</h1>
            <div className="options-row">
              <div className="options-param">{read('menu/music')}</div>
              <div className="options-value">{[...Array(11).keys()].map(i => <div key={i} onClick={() => { setVolume(i / 10, 'music'); this.forceUpdate(); }} className={"options-audio-tick" + (music >= i ? " audio-tick-active" : "")} />)}</div>
            </div>
            <div className="options-row">
              <div className="options-param">{read('menu/sfx')}</div>
              <div className="options-value">{[...Array(11).keys()].map(i => <div key={i} onClick={() => { setVolume(i / 10, 'sfx'); this.forceUpdate(); }} className={"options-audio-tick" + (sfx >= i ? " audio-tick-active" : "")} />)}</div>
            </div>
            <div className="options-row">
              <div className="options-param">Animations</div>
              <div className="options-value">
                <div className={"options-radio" + (fastmode ? " options-button" : "")}
                  onClick={() => {
                    if (fastmode) {
                      localStorage.removeItem('options.fastmode');
                      this.forceUpdate();
                    }
                  }}>
                  Normal</div>&nbsp;/&nbsp;
                <div className={"options-radio" + (!fastmode ? " options-button" : "")}
                  onClick={() => {
                    if (!fastmode) {
                      localStorage.setItem('options.fastmode', true);
                      this.forceUpdate();
                    }
                  }}>
                  Fast</div>
              </div>
            </div>
            <div className="options-row">
              <div className="options-param">Growth seq.</div>
              <div className="options-value">
                <div className={"options-radio" + (growthsequences !== "none" ? " options-button" : "")}
                  onClick={() => {
                    if (growthsequences !== "none") {
                      localStorage.setItem('options.growthsequences', 'none');
                      this.forceUpdate();
                    }
                  }}>
                  None</div>&nbsp;/&nbsp;
                <div className={"options-radio" + (growthsequences !== "fast" ? " options-button" : "")}
                  onClick={() => {
                    if (growthsequences !== "fast") {
                      localStorage.setItem('options.growthsequences', 'fast');
                      this.forceUpdate();
                    }
                  }}>
                  Fast</div>&nbsp;/&nbsp;
                <div className={"options-radio" + (growthsequences !== "complete" ? " options-button" : "")}
                  onClick={() => {
                    if (growthsequences !== "complete") {
                      localStorage.setItem('options.growthsequences', 'complete');
                      this.forceUpdate();
                    }
                  }}>
                  Full</div>
              </div>
            </div>
            {/* Fullscreen Options */}
            <div className="options-row">
              <div className="options-param">Fullscreen</div>
              <div className="options-value">
                <div className={"options-radio" + (!this.state.isFullscreen ? " options-button" : "")}
                  onClick={() => {
                    if (!this.state.isFullscreen) {
                      this.toggleFullscreen();
                    }
                  }}>
                  Yes</div>&nbsp;/&nbsp;
                <div className={"options-radio" + (this.state.isFullscreen ? " options-button" : "")}
                  onClick={() => {
                    if (this.state.isFullscreen) {
                      this.toggleFullscreen();
                    }
                  }}>
                  No</div>
              </div>
            </div>
            <div className="concede-button">
              <MainButton onClick={() => User.clear()} color="red">Delete save</MainButton>
            </div>
          </div>
        </Lightbox>
        <Lightbox open={this.state.status === "language"} onClose={() => this.setState({ status: null })}>
          <div className="options-box">
            <h1>{read('menu/language')}</h1>
            <div className="options-warning">{read('menu/languagewarning')}</div>
            <div className="options-row">
              <div onClick={() => this.changeLanguage("en")} className={"options-language" + (language === "en" ? " options-language-current" : "")}>{"English"}</div>
            </div>
            <div className="options-row">
              <div onClick={() => this.setState({ status: "options" })} className="options-back">{read('menu/back')}</div>
            </div>
          </div>
        </Lightbox>
        <div onClick={() => this.setState({ status: "options" })} className="options-icon" />
      </div>
    );
  }
}
