import Library from './scene/utility/Library';
import { saveAs } from 'file-saver';
import { updateSaveData } from './VersionUpdater';
import CryptoJS from 'crypto-js';

const version = '0.2';

const champions = Library.champions;

let premium = false;

let items = { version }

const difficultyOptions = ["casual", "challenge"];
let difficulty = localStorage.getItem("difficulty");

if (!difficulty || !difficultyOptions.includes(difficulty)) {
	difficulty = "challenge";
	localStorage.setItem("difficulty", "challenge");
}

if (localStorage.getItem("user.save")) {

	let storage = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem("user.save"), process.env.REACT_APP_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8));
	if(storage.progress)
		updateSaveData(storage);
	items = Object.assign({}, storage, { version })
}

export default class User {

	static updateStorage () {

		localStorage.setItem("user.save", CryptoJS.AES.encrypt(JSON.stringify(items), process.env.REACT_APP_ENCRYPTION_KEY).toString());
	}

	static setItem (item, value) {

		items[item] = value;
		User.updateStorage();
	}

	static get difficulty () {

		return difficulty;
	}

	static set difficulty (value) {

		difficulty = value;
		localStorage.setItem("difficulty", value);
	}

	static getItem (item) {

		return items[item];
	}

	static get currentRun () {

		let run = items["run.save"];
		if (run) {
			run = JSON.parse(run);
			if (premium)
				return run;
			let runmodel = Library.getChampion(run.champion.model);
			if (runmodel.premium)
				return;
			let maxlevel = runmodel.levelLimit;
			if (run.depth > maxlevel + (run.storyEvent ? 1 : 0))
				return;
			if (run.champion.level > maxlevel)
				return;
			return run;
		}
	}

	static get championSelect () {

		let select = items["champion.select"];
		if (select) {
			if (premium)
				return select;
			let runmodel = Library.getChampion(select);
			let runpremium = runmodel.premium;
			if (runpremium)
				return 1;
			return select;
		}
		return 1;
	}

	static removeItem (item) {

		delete items[item];
		User.updateStorage();
	}

	static get progress () {

		return progress;
	}

	static get stats () {

		return stats;
	}

	static get runes () {

		return User.getItem("runes") || 0;
	}

	static set runes (value) {

		User.setItem("runes", value);
		User.updateStorage();
	}

	static unlockPremium () {

		premium = true;
	}

	static get premium () {

		return premium;
	}

	static save () {

		/*const saveData = JSON.stringify({
		    progress: localStorage.getItem('progress') || '',
		    gallery: localStorage.getItem('gallery') || '',
		    championSelect: localStorage.getItem('champion.select') || '',
		    language: localStorage.getItem('language') || '',
		    runLog: localStorage.getItem('run.log') || '',
		    runSave: localStorage.getItem('run.save') || ''
		});*/
    	const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(items), process.env.REACT_APP_ENCRYPTION_KEY).toString();
    	const blob = new Blob([encryptedData], { type: 'text/plain;charset=utf-8' });
    	saveAs(blob, 'mazeofambition.save');
	}

	static load (file) {

	    if (file) {
	      const reader = new FileReader();
	      reader.onload = (e) => {
	        const encryptedData = e.target.result;
	        try {
	          const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_ENCRYPTION_KEY);
	          const originalData = bytes.toString(CryptoJS.enc.Utf8);
	          //localStorage.setItem('save', originalData);
	          const data = JSON.parse(originalData);;
	          items = Object.assign({}, data);
	          User.updateStorage();
	          console.log('Data loaded');
	          window.history.pushState(null, null, '/play')
	          window.location.reload(false);
	        } catch (error) {
	          console.error('Error decrypting file:', error);
	        }
	      };
	      reader.readAsText(file);
	    }
	}

	static clear () {

		items = { version };
		User.updateStorage();
		console.log('Data cleared');
		window.history.pushState(null, null, '/play')
		window.location.reload(false);
	}

	static completeQuest (difficulty, champion) {

		let p = progress[difficulty][champion];
		let quest = Library.getQuest(p.quest);
		p.completion++;

		quest.unlocks.forEach(unlock => {
			switch (unlock.type) {
			case "level":
				p.level = Math.max(p.level, unlock.value);
				break;
			default:
				break;
			}
		});
		p.quest = quest.next;

		User.setItem('progress', JSON.stringify(progress));
		User.updateStorage();
		if (difficulty === "challenge" && progress.casual[champion].quest === quest.key)
			User.completeQuest("casual", champion);
	}

	static addStats (difficulty, type, champion, value) {

		stats[difficulty][champion][type] += value;
		User.setItem('stats', JSON.stringify(stats));
		User.updateStorage();
	}

	static addRun (run) {

      	const runStats = run.stats;
		User.addStats(run.difficultySetting, "runsPlayed", run.champion.model.key, 1);
        User.addStats(run.difficultySetting, "roomsCleared", run.champion.model.key, runStats.roomsCleared);
        User.addStats(run.difficultySetting, "bossesSlain", run.champion.model.key, runStats.bossesSlain);
		stats[run.difficultySetting][run.champion.model.key].runs.push({
			deck: run.deck.map(c => c.key),
			stats: run.stats,
    		date: new Date().toISOString()
		})
		if (stats[run.difficultySetting][run.champion.model.key].runs.length > 10)
   			 stats[run.difficultySetting][run.champion.model.key].runs.shift();
		User.setItem('stats', JSON.stringify(stats));
		User.updateStorage();
	}

	static getQuest (difficulty, champion, run) {

		let p = progress[difficulty][champion];
		if (run && run.storyEvent)
			return;
		if (!p.quest)
			return;
		let quest = Library.getQuest(p.quest);
		if (quest.premium && !premium)
			return null;
		let active = false;
		if (quest.event && run) {
			switch (quest.event.condition.type) {
			case "level":
				if (run.champion.level >= quest.event.condition.value)
					active = true;
				break;
			default:
				break;
			}
		}
		return active ?
			{ key: quest.key, text: quest.event.text, active: true, callback: () => {
				run.startStoryEvent(quest.event.enemies, quest.event.background, quest.event.audio);
			} } :
			{ key: quest.key, text: quest.text };
		/*if (p.level === 1)
			return { text: "Clear the first floor " + (difficulty === "casual" ? "(1-12)." : "(1-16).") };
		else if (p.level === 2) {
			if (premium && p.story >= 1)
				return { text: "Clear the second floor " + (difficulty === "casual" ? "(2-12)." : "(2-16).") };
			switch (champion) {
			case 1:
				if (p.story >= 1)
					return;
				if (run && run.champion.level >= 2)
					return { text: "Fight Petra now!", active: true, callback: () => {
						run.startStoryEvent([1001], "event1", "amber");
					} }
				return { text: "Reach level 2 and fight Petra" };
			case 2:
				if (p.story >= 1)
					return;
				if (run && run.champion.level >= 2)
					return { text: "Meet Jenny now!", active: true, callback: () => {
						run.startStoryEvent([2001], "love", "ruby");
					} }
				return { text: "Reach level 2 and meet Jenny" };
			case 3:
				if (p.story >= 1)
					return;
				if (run && run.champion.level >= 2)
					return { text: "Attend the interview now!", active: true, callback: () => {
						run.startStoryEvent([3001], "event2", "pearl");
					} }
				return { text: "Reach level 2 and attend the interview" };
			default: break;
			}
		}*/
	}

	static hasSeen (cutscene) {

		return gallery.includes(cutscene);
	}

	static watch (cutscene) {

		if (this.hasSeen(cutscene))
			return;
		gallery.push(cutscene);
		User.setItem('gallery', JSON.stringify(gallery));
	}
}

let progress = User.getItem('progress') ? JSON.parse(User.getItem('progress')) : {}
let stats = User.getItem('stats') ? JSON.parse(User.getItem('stats')) : {}
let gallery = User.getItem('gallery') ? JSON.parse(User.getItem('gallery')) : []

difficultyOptions.forEach(diff => {
	
	if (!progress[diff])
		progress[diff] = {};
	if (!stats[diff])
		stats[diff] = {};

	Object.values(champions).forEach(c => {
		if (!progress[diff][c.key])
			progress[diff][c.key] = {
	          completion: 0,
	          quest: (c.key-1) * 1000 + 1,
	          level: 1
	        }
		if (!stats[diff][c.key])
			stats[diff][c.key] = {
	          runsPlayed: 0,
	          roomsCleared: 0,
	          bossesSlain: 0,
	          timePlayed: 0,
	          runs: []
	        }
	})
})