import React, { Component } from 'react';
import './Credit.css';
import SocketManager from '../../SocketManager';
import User from '../../User';

import { read } from '../../TextManager';

export default class Credit extends Component {

  state = {
    status: null,
    runes: User.runes
  }

  render () {

    return (
      <div className="nav-item nav-credit">
        <div className="nav-credit-count">{ Math.min(this.state.runes, 99999) }</div>
        <div className="runes-icon"/>
      </div>
    );
  }
}

