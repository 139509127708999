import Bloc from './Bloc.js';
import Types from './Types.js';

export default class CompareModels extends Bloc {

	constructor (src, ctx) {

		super("cmpmodels", src, ctx);
		this.f = (src, ins, props) => {
			return [ins[0] && ins[1] && ins[0].key === ins[1].key];
		}
		this.types = [Types.model, Types.model];
	}
}