import React, { Component } from 'react';
import './Campfire.css';
import Card from '../../components/cards/Card';
import Library from '../utility/Library';
import { Tooltip } from 'reactstrap';
import Img from '../../StableImage';

const champions = Library.champions;

export default class Campfire extends Component {

  state = {

    tooltip: null
  }

  toggleTooltip (tooltip) {

    this.setState({tooltip: tooltip === this.state.tooltip ? null : tooltip});
  }

  render () {

    let tooltips = []

    return (
      <div className="game-campfire">
        <div className="lightbox-container fight-reward">
          <div className="lightbox-inner">
            <div className="fight-reward-champion">
              <Img className="fight-reward-champion-img" src={this.props.src.champion.model.portrait[this.props.src.champion.level-1]}/>
            </div>
            <div className="fight-reward-info">
            {
              this.props.choices.map((c,i) => 
                  <div key={i} className="campfire-choice" onClick={e => this.props.onSelect(c)}>
                    {
                        (() => {
                          switch (c) {
                          case "rest": return (
                            <div className="campfire-choice-inner">
                              <h3>Rest</h3>
                              <span>Recover&nbsp;<span className="campfire-info-hp">{this.props.src.restHealing}</span>&nbsp;HP.</span>
                            </div>
                            );
                          case "explore": return (
                            <div className="campfire-choice-inner">
                              <h3>Explore</h3>
                              <span>Add a card to your deck.</span>
                            </div>
                            );
                          case "worship": return (
                            <div className="campfire-choice-inner">
                              <h3>Worship</h3>
                              <span>
                                {
                                  this.props.src.worshipExp ? <span>Gain&nbsp;<span className="campfire-info-exp">{this.props.src.worshipExp}</span>&nbsp;exp.</span> : ""
                                }
                                {
                                  this.props.src.worshipCorruption ? <span>Lose&nbsp;<span className="campfire-info-corruption">{this.props.src.worshipCorruption}</span>&nbsp;corruption.</span> : ""
                                }
                              </span>
                            </div>
                            );
                          default: return (
                            <div className="campfire-choice-inner">
                              <h3>{ c.type === "shop" ? "Shop" : { easy: "Easy fight", medium: "Average fight", elite: "Hard fight", boss: "Boss fight" }[c.difficulty] }</h3>
                              { c.type === "shop" ? <div className="campfire-loot-pieces"><div className="fight-loot-piece"><div className="loot-icon"><Img src="/images/shop.png"/><span>Buy cards and items</span></div></div></div> : "" }
                              { c.type === "fight" && c.difficulty === "boss" ? <div className="campfire-loot-pieces"><div className="fight-loot-piece"><div className="loot-icon"><Img src="/images/boss.png"/><span>Ready to fight</span></div></div></div> : "" }
                              {
                                c.reward && c.difficulty !== "boss" ? 
                                <div className="campfire-loot-pieces">
                                {
                                  Object.keys(c.reward).map((type, j) =>
                                    <div key={i + '-' + j} className="fight-loot-piece">
                                      {(() => {
                                        switch (type) {
                                        case "card": return <div className="loot-icon"><Img src="/images/card.png"/><span>New card</span></div>
                                        case "gold": return <div className="loot-icon"><Img src="/images/gold.png"/><span>{c.reward.gold} gold</span></div>
                                        case "exp": return <div className="loot-icon"><Img src="/images/exp.png"/><span>{c.reward.exp} exp</span></div>
                                        case "item":
                                          let item = c.reward.item;
                                          tooltips.push(<Tooltip key={"tooltip-item-" + j} className="tooltip" placement="top" isOpen={this.state.tooltip === "item-" + item.key} target={"loot-item-" + item.key} toggle={() => this.toggleTooltip("item-" + item.key)}>{ item.effect }</Tooltip>)
                                          return <div id={"loot-item-" + item.key} className="loot-icon"><Img src={ item.img }/><span>{item.name}</span></div>
                                        }
                                      })()}
                                    </div>
                                  )
                                }
                                </div> : ""
                              }
                            </div>
                            );
                          }
                        })()
                      }
                  </div>
              )
            }
            </div>
            <div className="campfire-tooltips">{ tooltips }</div>
          </div>
        </div>
      </div>
    );
  }
}

