import Location from './Location';

export default class Hand extends Location {

	constructor (master) {

		super(master, "hand");
	}

	get maxsize () {

		return Math.min(20, 10 + this.master.player.getStatusValue(37));
	}
}