import Library from '../../utility/Library';

export default class Types {

	static string (value, src) {

		return value;
	}

	static int (value, src) {

		return typeof value === 'string' ? parseInt(value, 10) : value;
	}

	static bool (value, src) {

		return (typeof value === 'string' ? (value === "true" ? true : false) : value) || false;
	}

	static statustype (value, src) {

		return value;
	}

	static event (value, src) {

		return value;
	}

	static value (value, src) {

		return value;
	}

	static data (value, src) {

		return value || [];
	}

	static property (value, src) {

		return value || [];
	}

	static card (value, src) {

		if (!(typeof value === 'string')) 
			return value;
		switch (value) {
		case 'this': return src;
		default: return src;
		}
	}

	static entity (value, src) {

		if (!(typeof value === 'string'))
			return value;
		switch (value) {
		case 'champion': return src.master.player;
		default: return src.id.type === "enemy" ? src : (src.id.type === "status" ? src.entity : src.master.player);
		}
	}

	static location (value, src) {

		if (!(typeof value === 'string'))
			return value;
		switch (value) {
		case 'this': return src.location;
		case 'hand': return src.master.hand;
		case 'deck': return src.master.deck;
		case 'discard': return src.master.discard;
		default: return null;
		}
	}

	static cardfilter (value, src) {

		if (!(typeof value === 'string')) {
			if (value === null)
				return (card) => true;
			return value;
		}
		switch (value) {
		case 'attack': return (card) => card.type === "attack";
		case 'special': return (card) => card.type === "special";
		case 'hex': return (card) => card.type === "hex";
		default: return (card) => true;
		}
	}

	static entityfilter (value, src) {

		if (!(typeof value === 'string'))
			return value;
		switch (value) {
		case "champion": return (entity) => entity.id === "champion";
		case "enemy": return (entity) => entity.id !== "champion";
		case "dead": return (entity) => entity.hp <= 0;
		case "dead enemy": return (entity) => entity.id !== "champion" && entity.hp <= 0;
		default: return (entity) => true;
		}
	}

	static model (value, src) {

		switch (value) {
		case 'this': return src.model;
		default: return Library.getCard(value);
		}
	}

	static modelfilter (value) {

		if (!(typeof value === 'string'))
			return value;
		switch (value) {
		case 'unit': return model => model.type === "unit";
		case 'spell': return model => model.type === "spell";
		default: return model => true;
		}
	}

	static effecttype (value, src) {

		if (!(typeof value === 'string'))
			return value;
		switch (value) {
		case 'fanfare': return target => target.type === "fanfare";
		case 'last will': return target => target.type === "lastwill";
		default: return target => true;
		}
	}

	static period (value, src) {

		if (!(typeof value === 'string'))
			return value;
		switch (value) {
		case 'this turn': return 1;
		case 'all game': return 0;
		case 'last event': return 2;
		default: return 0;
		}
	}

	static timestamp (value, src) {

		return value;
	}
}