import Bloc from './Bloc.js';
import Types from './Types.js';

export default class BreakCard extends Bloc {

	constructor (src, ctx) {

		super("breakcard", src, ctx);
		this.f = (src, ins) => {
			let card = ins[0];
			if (ins[0] && ins[0].type && ins[0].no !== undefined)
				card = src.master.find(ins[0]);
			if (!card)
				return [null, null, null, null, null]
			return [card.model, card.location, card.type, card.mana, card.level];
		}
		this.types = [Types.card];
	}
}