import Wait from './view/animation/Wait';
import AddToHand from './view/animation/AddToHand';
import RemoveFromHand from './view/animation/RemoveFromHand';
import Attack from './view/animation/Attack';
import Damage from './view/animation/Damage';
import BlockDamage from './view/animation/BlockDamage';
import NewIntent from './view/animation/NewIntent';
import Intent from './view/animation/Intent';
import Status from './view/animation/Status';
import Block from './view/animation/Block';
import Shuffle from './view/animation/Shuffle';
import Combination from './view/animation/Combination';
import TimeDown from './view/animation/TimeDown';

export default class Sequencer {

	constructor (master, dispatch) {

		this.master = master;
		this.queue = [];
		this.current = null;
		this.dispatch = dispatch;
		this.logs = [];
		/*this.extraturns = [0, 0];
		this.currentturn = -1;*/
	}

	add (event) {

		if (this.current)
			this.queue.push(event);
		else
			this.read(event);
	}

	next () {

		this.current = null;
		if (this.queue.length > 0)
			this.read(this.queue.shift());
	}

	read (event) {

		if (this.master.stopped)
			return;
		let n = event.n;
		this.logs.push(n);
		if (event.callback)
			event.callback();
		var anim = this.notifToAnim(n);
		if (anim) {
			if (anim.sync)
				this.current = anim;
			if (!anim.before)
				this.dispatch(n);
			anim.start(() => {
				if (anim.before)
					this.dispatch(n);
				this.next();
			});
		} else {
			this.dispatch(n);
			this.next();
		}

		/*var anim = this.notifToAnim(n);
		if (anim) {
			if (anim.sync)
				this.current = anim;
			if (anim.before) {
				anim.start(() => {
					this.dispatch(n);
					this.next();
				});
			}
			else {
				this.dispatch(n);
				this.resume = () => {
					delete this.resume;
					anim.start(() => {
						this.next();
					});
				};
			}
		} else {
			this.dispatch(n);
			this.next();
		}*/
	}

	update () {

		if (!this.current.sync)
			this.next();
	}

	notifToAnim (n) {

	  switch(n.type) {
	    case "gamestate": return;
	    case "wait":
	    	return new Wait(this.master, n.data[1]);
	    case "startfight":
	    	return new Wait(this.master, 200);
	    case "animation": {
	    	switch (n.data[0]) {
	    	case "spark": return new Wait(this.master, 300);
	    	default: return null;
	    	}
	    }
	    case "cast.before": {
	    	if (n.data[0].type === "attack")
	    		return new Attack(this.master, "player");
	    	return null;
	    	//return new Wait(this.master, 200);
	    }
	    case "cast": {
	    	return null;
	    	//return new Wait(this.master, 200);
	    }
	    case "intent": {
	    	return new NewIntent(this.master, n.data[0].id.no);
	    }
	    case "shufflecard":
	    	return new Shuffle(this.master, n.data[0].model, n.data[1].type === "discard");
	    case "blockgain": {
	    	return new Block(this.master, n.data[0].id === "player" ? "player" : n.data[0].id.no);
	    }
	    case "blockdamage": {
	    	return new BlockDamage(this.master, n.data[0].id === "player" ? "player" : n.data[0].id.no);
	    }
	    case "applystatus": {
	    	return new Status(this.master, n.data[0].id === "player" ? "player" : n.data[0].id.no, n.data[1]);
	    }
	    case "enemyact.before": {
	    	let intent = new Intent(this.master, n.data[0].id.no);
	    	if (n.data[1] === "attack")
	    		return new Combination(intent, new Attack(this.master, n.data[0].id.no));
	    	return intent;
	    	//return new Wait(this.master, 200);
	    }
	    case "enemyact": {
	    	if (n.data[1] === "attack")
	    		return new Wait(this.master, 300);
	    	return new Wait(this.master, 300);
	    	//return new Wait(this.master, 200);
	    }
	    case "attack.before": {
	    	return new Attack(this.master, n.data[0].no);
	    }
	    case "lifeloss": {
	    	return new Damage(this.master, n.data[0].id === "champion" ? "player" : n.data[0].id.no, n.data[2]);
	    }/*
	    case "nodamage": {
	    	return new Damage(this.master, n.data[0], 0);
	    }*/
	    case "movecard.before": {
	    	if (n.data[1]?.type === "hand") {
		    	let card = n.data[0];
		    	if (card)
		    		return new RemoveFromHand(this.master, n.data[0].id.no);
		    }
		    break;
		}
	    case "movecard": {
	    	if (n.data[2]?.type === "hand") {
		    	let card = n.data[0];
		    	if (card)
		    		return new AddToHand(this.master, n.data[0].id.no);
		    }
		    /*if (n.data[1] && n.data[1].type === "hand") {
		    	let card = this.master.state.model.find(n.data[0]);
		    	if (card)
		    		return new RemoveFromHand(this.master, n.data[0].no);
		    }*/
		    /*if (n.data[2] && n.data[2].type === "court") {
		    	return new Wait(this.master, 1000);
		    }*/
	    	break; }
	    case "timedown": {
	    	return new TimeDown(this.master);
	    }
	    default: return null;
	    }
	}
}