import './App.css';
import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import Home from './home/Home';
import Error from './home/Error';
import Loading from './home/Loading';
import Play from './play/Play';
import Story from './play/story/Story';
import Game from './play/game/Game';
import Multiplayer from './play/multiplayer/Multiplayer';
import Cards from './play/cards/Cards';
import Gallery from './play/gallery/Gallery';
import Progress from './play/progress/Progress';
import AgeCheck from './home/AgeCheck';
import PatreonRedirect from './patreon/PatreonRedirect';
import User from '../User';

import SocketManager from '../SocketManager';

import { read, getLanguage, setLanguage } from '../TextManager';

const offlineMode = true; //localStorage.getItem('offlineMode') || ;

export default class App extends Component {

  constructor (props) {

    super(props);
    this.home = React.createRef();
    let io = SocketManager.master;
    if (!offlineMode) {
      io.start();
      io.onStatusChange = status => {

        if (!this.state.connected && status === 'connected') {
          this.setState({connected: true});
        } else if (this.state.connected && status === "disconnected") {
          this.setState({connected: false}, () => io.start());
          checkLibraryVersion = false;
        }
      }
    }

    setTimeout(() => this.setState({started: true}), 300)

    this.state = {
      connected: false,
      ageCheck: sessionStorage.getItem("agecheck")
    };

    switch (window.location.pathname) {
    case '/en': setLanguage('en'); break;
    case '/fr': setLanguage('fr'); break;
    default: break;
    }
  }

  render () {

    if (!this.state.started)
      return <div className="app"/>;

    if (!this.state.ageCheck)
      return <div className="app"><AgeCheck onCheck={() => {
        sessionStorage.setItem("agecheck", true);
        this.setState({ageCheck: true});
      }}/></div>

    if (!this.state.connected && !offlineMode)
      return <div className="app"><Error startup={1000}>{ read('messages/connecterror') }</Error></div>;

    return (
      <div className="app">
        <Router>
          <Routes>
            <Route path="/" element={<Home ref={this.home}/>}/>
            <Route path="/play" element={<Dynamo><Play/></Dynamo>}/>
            <Route path="/story" element={<Dynamo><Story/></Dynamo>}/>
            <Route path="/game" element={<Dynamo><Game/></Dynamo>}/>
            <Route path="/multiplayer" element={<Dynamo><Multiplayer/></Dynamo>}/>
            <Route path="/cards" element={<Dynamo><Cards/></Dynamo>}/>
            <Route path="/gallery" element={<Dynamo><Gallery/></Dynamo>}/>
            <Route path="/progress" element={<Dynamo><Progress/></Dynamo>}/>
            <Route path="/oauth" element={<Dynamo><PatreonRedirect/></Dynamo>}/>
            <Route
                path="*"
                element={<Navigate to="/" replace />}
            />
          </Routes>
        </Router>
      </div>
    );
  }
}

var checkLibraryVersion = false;

class Dynamo extends Component {

  constructor (props) {

    super(props);

    setTimeout(() => {
      if (!this.state.loaded) {
        this.showLoadingScreenFade = true;
        if (this.state.loaded)
          this.setState({loaded:false});
      }
    }, 500)

    if (localStorage.getItem("patreon.token")) {
      
      this.state = {
        loading: 0,
        loaded: false
      }

      this.checkPatreonToken();

    } else 
      this.state = {
        loading: 0,
        loaded: true
      }
  }

  checkPatreonToken = async () => {

    try {
        const response = await fetch(`/.netlify/functions/patreon-check?token=${encodeURIComponent(localStorage.getItem("patreon.token"))}`);
        const data = await response.json();

        if (data.error) {
          localStorage.removeItem("patreon.token");
          this.setState({ loaded: true });
        } else {
          if (data.elite)
            User.unlockPremium();
          else
            localStorage.removeItem("patreon.token");
          this.setState({ loaded: true });
        }
      } catch (error) {
          localStorage.removeItem("patreon.token");
          this.setState({ loaded: true });
      }
  }

  render () {

    if (!this.state.loaded)
      return <Loading value={this.state.loading}>Checking Patreon status</Loading>;

    return this.props.children;
  }
}

