import Animation from './Animation';

export default class Block extends Animation {

	constructor (master, entity) {

		super(master, 450);
		this.entity = entity;
		//this.loadAudio("block");
	}

	run () {

		var el = document.querySelector("#game-entity-" + this.entity + " .game-entity-main .game-entity-afflict-status:not(.status-anim)");
		var text = document.querySelector("#game-entity-" + this.entity + " .game-entity-main .game-entity-afflict-status:not(.status-anim) .game-entity-afflict-status-text");
		var img = document.querySelector("#game-entity-" + this.entity + " .game-entity-main .game-entity-afflict-status:not(.status-anim) .game-entity-afflict-status-img");
		if (el) {
			el.classList.add("status-anim");
			el.classList.add("block");
			img.src = '/images/block.png';
			text.textContent = "";
			setTimeout(() => { el.classList.remove("status-anim"); el.classList.remove("block"); }, 600);
		}
	}
}