import React, { Component, createRef } from 'react';
import './Play.css';
import Nav from '../nav/Nav';
import MenuButton from '../buttons/MenuButton';
import Flowers from '../other/flowers/Flowers';
import User from '../../User';

import { read } from '../../TextManager';

const OAUTH_CLIENT_ID = "dEwJq9p7qYkYcczZOSplEbA6YEmRRprHDXgI2vKUlh8rT6-nBAf2QbLhCWj9lCLT";
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;

export default class Play extends Component {

  constructor(props) {

    super(props);
    this.fileInputRef = createRef();
  }

  handleConnectWithPatreon = () => {

    const authorizationUrl = `https://www.patreon.com/oauth2/authorize?response_type=code&client_id=${OAUTH_CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}`;
    window.location.href = authorizationUrl;
  };

  render () {

    return (
      <div className="main-page play-page">
        <Flowers/>
        <Nav/>
        <div className="main">
          <div className="play-buttons">
            <MenuButton to="/story">{ "New run" }</MenuButton>
            { User.currentRun ? <MenuButton to="/game">{ "Continue" }</MenuButton> : "" }
            <MenuButton to="/cards">{ read('menu/collection') }</MenuButton>
            { User.getItem("gallery") ? <MenuButton to="/gallery">Gallery</MenuButton> : "" }
            <MenuButton to="/progress">Progress</MenuButton>
            <MenuButton onClick={() => User.save()}>Save</MenuButton>
            <MenuButton onClick={() => this.fileInputRef.current.click()}>Load</MenuButton>
            <input style={{ display: 'none' }} ref={this.fileInputRef} type="file" accept=".save" onChange={e => User.load(e.target.files[0])} />
            { User.premium ? "" : <MenuButton onClick={() => this.handleConnectWithPatreon()}>Connect with Patreon</MenuButton> }
            <MenuButton to="/">{ read('menu/titlescreen') }</MenuButton>
          </div>
        </div>
      </div>
    );
  }
}

