import Bloc from './Bloc.js';
import Types from './Types.js';

export default class Heal extends Bloc {

	constructor (src, ctx) {

		super("heal", src, ctx, true);
		this.f = (src, ins) => {
			if (ins[0].id && ins[0].id.type === "enemy" && ins[0].dead)
				ins[0].loseIntent();
			ins[0].heal(ins[1] === undefined || ins[1] === null ? ins[0].maxhp : ins[1]);
			return [];
		};
		this.types = [Types.entity, Types.int];
	}
}