import Animation from './Animation';
import './TimeDown.css';

export default class Intent extends Animation {

	constructor (master) {

		super(master, 600);
		//this.loadAudio("draw");
	}

	run () {

		var el = document.querySelector("#scene");
		if (el) {
			el.classList.add("time-down-anim");
			setTimeout(() => el.classList.remove("time-down-anim"), 700);
		}
	}
}