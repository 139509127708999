import React, { Component } from 'react';
import './CardSelector.css';
import Card from '../../components/cards/Card';
import Library from '../utility/Library';
import { Tooltip } from 'reactstrap';
import Img from '../../StableImage';

const statuses = Object.values(Library.statuses);

export default class CardSelector extends Component {

  state = { tooltip: null }

  computeEffect (text, tkey) {

    let splits = [];
    // eslint-disable-next-line
    let texts = text.split(/\[[^\[]+\]|\{[^\{]+\}|\n|D:\d+|B:\d+/);
    // eslint-disable-next-line
    let matches = text.match(/\[[^\[]+\]|\{[^\{]+\}|\n|D:\d+|B:\d+/g);
    if (matches)
      matches.forEach((match,i) => {
        let el = match.slice(1, -1);
        splits.push(<span key={i+"a" + "-key-" + tkey}>{ texts[i] }</span>);
        if (match[0] === '{') {
          let key = match.substring(1, match.length - 1)
          splits.push(<span className="token" onClick={() => this.props.onFocus(Library.cards[key])} key={i + "-key-" + tkey}>{Library.cards[key].name}</span>);
          /*let slices = el.split('/');
          let key = slices[0];
          let ntoken = this.state.token.slice(); ntoken.push(Library.cards[key]);
          let repeat = this.current.key && key === this.current.key.toString();
          splits.push(<span onClick={repeat ? () => {} : () => this.setState({tooltip: null, token: ntoken})} key={i} className={"token" + (repeat ? " repeat-token" : "")} id={'effect-' + i}>{ slices.length > 1 ? slices[1] : Library.cards[key].name }</span>);*/
        } else if (match[0] === '[') {
          let slices = el.split('/');
          let keyword = slices[0];
          let status = statuses.filter(s => s.name === keyword)[0];
          splits.push(<span key={i + "-key-" + tkey} className="keyword" id={'effect-' + i + "-key-" + tkey} onClick={() => this.toggleTooltip(i)}>{status.keyword ? "" : <Img key={keyword + '-' + i} className="keyword-icon" src={"/images/statuses/" + keyword + ".png"} alt=""/>}{slices.length > 1 ? slices[1] : status.name}</span>);
          splits.push(<Tooltip key={i+"t" + "-key-" + tkey} className="tooltip" placement="top" isOpen={this.state.tooltip === i + "-" + tkey} target={"effect-" + i + "-key-" + tkey} toggle={() => this.toggleTooltip(i + "-" + tkey)}>{ status.effect }</Tooltip>);
        
        } else if (match[0] === '\n') {
          splits.push(<br key={i + "-key-" + tkey}/>);
        } else if (match[0] === 'D') {
          let initialvalue = parseInt(match.substring(2), 10);
          splits.push(<span key={i + "-key-" + tkey} className="num-effect damage-effect">{ initialvalue }</span>);
        } else if (match[0] === 'B') {
          let initialvalue = parseInt(match.substring(2), 10);
          splits.push(<span key={i + "-key-" + tkey} className="num-effect block-effect">{ initialvalue }</span>);
        }
      });
    splits.push(<span key={"end" + "-key-" + tkey}>{ texts[texts.length-1] }</span>);
    return splits;
  }

  toggleTooltip (tooltip) {

    this.setState({tooltip: tooltip === this.state.tooltip ? null : tooltip});
  }

  effectLength (effect) {

    let length = effect.length;
    if (length > 85)
      return "verylong";
    else if (length > 60)
      return "long";
    return "regular";
  }

  render () {

    return (
      <div className="fight-loot-card-selector">
        <div className="fight-loot-card-options">
        {
          this.props.options.map((option, i) =>
            <div key={i} className="fight-loot-card-option">
              <div className="fight-loot-card-option-card" onClick={() => this.props.onSelect(i)}>
                <div className="fight-loot-card-option-card-inner">
                  <Card src={option}/>
                </div>
              </div>
              <div className="game-card-info">
                <div className="game-card-info-inner-selector">
                  <div className="game-card-info-type">{option.type}</div>
                  <div className={"game-card-info-effect game-effect text-" + this.effectLength(option.effect)}>{this.computeEffect(option.effect, i)}</div>
                </div>
              </div>
            </div>
          )
        }
        </div>
        { this.props.onSkip ? <div className="fight-loot-card-skip" onClick={() => this.props.onSkip()}>Skip</div> : "" }
      </div>
    );
  }
}

