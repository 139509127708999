import React, { Component } from 'react';
import { Input, Label, Button, Tooltip } from 'reactstrap';
import './Cards.css';
import Nav from '../../nav/Nav';
import Back from '../../Back';
import Card from '../../cards/Card';
import Champion from '../../cards/Champion';
import Deck from '../../cards/Deck';
import CardBox from '../../cards/CardBox';
import BasicButton from '../../buttons/BasicButton';
import MainButton from '../../buttons/MainButton';
import Library from '../../../scene/utility/Library';
import SocketManager from '../../../SocketManager';
import Lightbox from '../../utility/Lightbox';
import Loader from '../../utility/Loader';

import sorter from '../../cards/CollectionSorter';
import User from '../../../User';
import { read } from '../../../TextManager';

const PAGE_SIZE = 20;

export default class Cards extends Component {

  state = {
    focus: null,
    tooltip: null,
    champion: 1,
    filter: {
      search: "",
      type: "",
      color: "",
      collectable: true,
      orderBy: "key"
    },
    hiding: {
      decks: true,
      champions: false
    },
    page: {
      champions: 0
    },
    search: null,
    action: null,
    collection: undefined// JSON.parse(localStorage.getItem('collection'))
  }

  componentDidMount () {

    SocketManager.master.onDeckbuildUpdate = deck => {
      if (!deck) {
        this.setState({deck, action: null, waiting: false, newdeck: false});
      } else if (this.state.newdeck || (this.state.deck && deck.key === this.state.deck.key)) {
        this.setState({deck, action: null, waiting: false, newdeck: false});
        let deckname = document.getElementById("deckname-input");
        if (deckname)
          deckname.value = deck.deckname;
      } else
        this.forceUpdate()
    }
    SocketManager.master.onCollectionUpdate = collection => this.setState({collection, page: {champions: 0}});
  }

  componentWillUnmount () {

    delete SocketManager.master.onDeckbuildUpdate;
  }

  capitalize (string) {

    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  render () {

    let cards = Object.values(Library.cards);
    let progress = User.progress.casual;
    cards = cards.filter(card => card.level <= progress[this.state.champion].level);
    //cards = cards.filter(card => card.basic || this.state.collection.cards.includes(card.key));
    cards = sorter.filter(cards, this.state.filter);

    let premium = User.premium;

    let allChampions = Object.values(Library.champions);
    let fullChampions = premium ? allChampions : allChampions.filter(champion => !champion.premium);//.filter(champion => champion.basic || this.state.collection.champions.includes(champion.key))
    fullChampions = sorter.filter(fullChampions, Object.assign({}, this.state.filter, {orderBy: "color"})).sort((a, b) => a.key - b.key);

    let champions = fullChampions;//.slice(PAGE_SIZE * this.state.page.champions, PAGE_SIZE * this.state.page.champions + 20);

    let decks = []// JSON.parse(localStorage.getItem('decks')) || [];

    let fullColors = {};
    champions.forEach(c => fullColors[c.key] = []);
    Object.keys(fullColors).forEach(color => fullColors[color] = cards.filter(c => c.champion === parseInt(color,10)));

    /*let colors = {};
    Object.keys(fullColors).forEach(color => {
      colors[color] = fullColors[color].slice(PAGE_SIZE * this.state.page[color], PAGE_SIZE * this.state.page[color] + 20);
    });*/
    let colors = fullColors;

    let pages = { champions: Math.ceil(fullChampions.length / PAGE_SIZE) };
    /*Object.keys(fullColors).forEach(color => {
      pages[color] = Math.ceil(fullColors[color].length / PAGE_SIZE);
    });*/

    let champion = Library.champions[this.state.champion];

    let decklist = {};
    let uniques = [];
    let sortedDeck = [];
    if (this.state.deck) {
      this.state.deck.body.cards.forEach(no => {
        if (!decklist[no]) {
          decklist[no] = 1;
          uniques.push(Library.getCard(no));
        }
        else
          decklist[no]++;
      });
      sortedDeck = sorter.sort(uniques, "type");
    }

    let left = null, right = null;
    if (this.state.focus) {
      if (this.state.focus.mana === undefined) {
        if (this.state.focusmode === 'collection') {
          let index = champions.findIndex(champion => champion.key === this.state.focus.key);
          if (index >= 0) {
            if (index > 0)
              left = champions[index-1];
            if (index < champions.length-1)
              right = champions[index+1];
          }
        }
      } else {
        if (this.state.focusmode === 'collection') {
          Object.keys(colors).forEach(color => {
            let index = colors[color].findIndex(card => card.key === this.state.focus.key);
            if (index >= 0) {
              if (index > 0)
                left = colors[color][index-1];
              if (index < colors[color].length-1)
                right = colors[color][index+1];
            }
          })
        } else {
            let index = sortedDeck.findIndex(card => card.key === this.state.focus.key);
            if (index >= 0) {
              if (index > 0)
                left = sortedDeck[index-1];
              if (index < sortedDeck.length-1)
                right = sortedDeck[index+1];
            }
        }
      }
    }

    let levels = Array.from(new Set(Object.values(colors[this.state.champion]).map(obj => obj.level))).map(level =>
      colors[this.state.champion].filter(card => card.level === level));
    let maxLevel = premium ? champion.levelLimitPremium : champion.levelLimit;
    levels = levels.filter((list, level) => level <= maxLevel);

    return (
      <div className="main-page cards-page">
        {
          this.state.focus ?
          <div>
            <CardBox left={left ? () => this.setState({focus:left}) : undefined} right={right ? () => this.setState({focus:right}) : undefined} src={this.state.focus} open={true} onClose={() => this.setState({focus:null})}/>
            {
              this.state.deck ?
              <div className="cards-page-cardcountbuilder">
                <div onClick={() => {
                  if (this.state.focus.mana !== undefined) {
                    if (this.state.deck.body.cards.filter(key => key === this.state.focus.key).length > 0)
                      SocketManager.master.onDeckbuild("removecard", [this.state.deck.key, this.state.focus.key], this.state.deck.key);
                  } else {
                    if (this.state.deck.body.champions.filter(key => key === this.state.focus.key).length > 0)
                      SocketManager.master.onDeckbuild("removechampion", [this.state.deck.key, this.state.focus.key], this.state.deck.key);
                  }
                } } className={"cards-page-cardcountbutton " + ((this.state.focus.mana !== undefined ? this.state.deck.body.cards.filter(key => key === this.state.focus.key).length <= 0 : this.state.deck.body.champions.filter(key => key === this.state.focus.key).length <= 0) ? "cards-page-cardcountbuttonlock" : "")}>-</div>
                <div className="cards-page-cardcount">{this.state.focus.mana !== undefined ? this.state.deck.body.cards.filter(key => key === this.state.focus.key).length : this.state.deck.body.champions.filter(key => key === this.state.focus.key).length}</div>
                <div onClick={() => {
                  if (this.state.focus.mana !== undefined) {
                    if (this.state.deck.body.cards.filter(key => key === this.state.focus.key).length < 2)
                      SocketManager.master.onDeckbuild("addcard", [this.state.deck.key, this.state.focus.key], this.state.deck.key);
                  } else {
                    if (this.state.deck.body.champions.filter(key => key === this.state.focus.key).length < 1 && this.state.deck.body.champions.length < 3)
                      SocketManager.master.onDeckbuild("addchampion", [this.state.deck.key, this.state.focus.key], this.state.deck.key);
                  }
                } } className={"cards-page-cardcountbutton " + ((this.state.focus.mana !== undefined ? this.state.deck.body.cards.filter(key => key === this.state.focus.key).length >= 2 : this.state.deck.body.champions.filter(key => key === this.state.focus.key).length >= 1 || this.state.deck.body.champions.length >= 3) ? "cards-page-cardcountbuttonlock" : "")}>+</div>
              </div>
              : ""
          }
          </div>
          : ""
        }
        <Lightbox className="delete-box small" open={this.state.action === "delete"} onClose={() => this.setState({action: null})}>
          <h1>{ read('menu/askconfirmationdelete') }</h1>
          <MainButton color="red" onClick={() => { SocketManager.master.deckbuild("delete", this.state.deck.key); this.setState({waiting: true}); } }>{ read('menu/delete') }</MainButton>
        </Lightbox>
        {
          this.state.waiting ?
          <div className="lightbox-container">
            <div className="lightbox-inner">
              <Loader/>
            </div>
          </div>
          : ""
        }
        <Nav/>
        <div className="main">
          <div className="cards-page-nav"></div>
          
          <div className={"cards-page-list" + (this.state.hiding.decks ? "" : " restrained-list")}>
            <div className={"cards-page-deck-section" + (this.state.hiding.decks ? " hidden-decks" : "")}>
              <div className="cards-border" onClick={() => { this.setState({hiding: Object.assign({}, this.state.hiding, {decks: !this.state.hiding.decks}), deck: (this.state.hiding.decks ? this.state.deck : null)});}}><div className="cards-border-left">{ read('menu/deckbuilder') }</div><div className="cards-border-right">{this.state.hiding.decks ? "+" : "-"}</div></div>
              <div className="deck-list-carousel">
              <div className="card-list deck-list">
                {
                  decks.sort((a, b) => a.deckname > b.deckname ? 1 : -1).map((deck, i) => <div key={i} className={"listed-deck" + (this.state.deck && this.state.deck.key === deck.key ? " selected-deck" : "")} onClick={() => {
                      if (this.state.deck && this.state.deck.key === deck.key)
                        this.setState({deck: null});
                      else {
                        this.setState({deck});
                        let deckname = document.getElementById("deckname-input");
                        if (deckname)
                          deckname.value = deck.deckname;
                      }
                    }
                  }><Deck src={deck}/></div>)
                }
                </div>
              </div>
              {
                this.state.deck ?
                <div className="deckbuilder">
                  <div className="deckbuilder-nav">
                    <div className="deckbuilder-nav-row">
                      <div className="deckbuilder-name"><Input id="deckname-input" type="text" defaultValue={this.state.deck.deckname} onBlur={e => {
                        if (this.state.deck.deckname !== e.target.value) {
                          if (e.target.value.length > 0)
                            SocketManager.master.deckbuild("rename", this.state.deck.key, e.target.value);
                          else e.target.value = this.state.deck.deckname
                        }
                      }}/></div>
                    </div>
                    <div className="deckbuilder-nav-row">
                      <div className="deckbuilder-buttons">
                        <div className="deckbuilder-back"><MainButton onClick={() => this.setState({deck: null})}>{ read('menu/back') }</MainButton></div>
                        <div className="deckbuilder-delete"><MainButton color="red" onClick={() => this.setState({action: "delete"})}>{ read('menu/delete') }</MainButton></div>
                      </div>
                    </div>
                  </div>
                  <Tooltip target="deckbuilder-champion-count" isOpen={this.state.tooltip === "nonvaliddeck2"} toggle={() => this.setState({tooltip: (this.state.tooltip === "nonvaliddeck2" || this.state.deck.body.cards.length === 30) ? null : "nonvaliddeck2" })}>{ read('menu/nonvalidchampioncount') }</Tooltip>
                  <div id="deckbuilder-champion-count" className="deckbuilder-h">{"Champions: " + this.state.deck.body.champions.length + " / 3 " + (this.state.deck.body.champions.length === 3 ? "✔" : "✘")}</div>
                  <div className="card-list">
                  {
                    this.state.deck.body.champions.map(key => champions.find(champion => champion.key === key)).map((champion, i) => <div key={i} className="listed-card" onClick={() => this.setState({focus:champion, focusmode: 'deck'})}><Champion src={champion}/></div>)
                  }
                  </div>
                  <Tooltip target="deckbuilder-card-count" isOpen={this.state.tooltip === "nonvaliddeck"} toggle={() => this.setState({tooltip: (this.state.tooltip === "nonvaliddeck" || this.state.deck.body.cards.length === 30) ? null : "nonvaliddeck" })}>{ read('menu/nonvalidcardcount') }</Tooltip>
                  <div id="deckbuilder-card-count" className="deckbuilder-h">{"Cards: " + this.state.deck.body.cards.length + " / 30 " + (this.state.deck.body.cards.length === 30 ? "✔" : "✘")}</div>
                  <div className="card-list">
                  {
                    sortedDeck.map((card, i) => <div key={i} className="listed-cards" onClick={() => this.setState({focus: card, focusmode: 'deck'})}>{ [...Array(decklist[card.key]).keys()].map(i => <Card key={i} src={card}/>) }</div>)
                  }
                  </div>
                </div>
                : ""
              }
            </div>
            <div className="cards-page-championsandcards-section">
            {
              <div className="champions-section">
                <div id="champions-border" className="cards-border">
                  <div className="cards-border-left">
                    
                      { read('menu/champions') }
                    <span className="cards-border-cardcount">
                      { "(" + champions.length + ")" }
                    </span>
                     
                  </div>
                </div>
                <div className={"card-list champions " + (this.state.hiding.champions ? "invisible" : "")}>
                {
                  champions.map((champion, i) => <div key={champion.key} className={"champion-selector" + (this.state.champion === champion.key ? " champion-selected" : "")}><div className="listed-card" onClick={() => this.setState({focus:champion, focusmode: 'collection'})}><Champion src={champion}/></div><MainButton onClick={() => this.setState({champion: champion.key})}>Select</MainButton></div>)
                }
                </div>
              </div>
            }
              <div className="cards-border"><div className="cards-border-left">{ champion.name }<span className="cards-border-cardcount">{ "(" + colors[this.state.champion].filter(c => c.level <= maxLevel).length + ")" }</span></div><div className="cards-border-right"></div></div>
              
                    <div className="cards-border-left">
                      { read('cards/' + this.state.champion) }
                      <span className="cards-border-cardcount">
                        { "(" + colors[this.state.champion].length + ")" }
                      </span>
                    </div>
                    
                  </div>
                  <div className={"card-list cards " + (this.state.hiding[this.state.champion] ? "invisible" : "")}>
                  {
                    //colors[this.state.champion].map((card, i) => <div key={i} className="listed-card" onClick={() => this.setState({focus:card, focusmode: 'collection'})}><Card src={card}/></div>)
                  }
                  {
                    levels.map((list, level) =>
                      <div key={level} className="level-list">
                      {
                        list.map((card, i) => <div key={card.key} className="listed-card" onClick={() => this.setState({focus:card, focusmode: 'collection'})}><Card src={card}/></div>)
                      }
                      </div>
                    )
                  }
            </div>
          </div>
          </div>
        <Back to="/play"/>
      </div>
    );
/*
    return (
      <div className="main-page cards-page">
        <Nav/>
        <div className="main">
          <div className="card-list">
          {
            Object.keys(cards).map(k => <div key={k} className="listed-card" onClick={() => this.setState({focus:cards[k]})}><Card src={cards[k]}/></div>)
          }
          </div>
          <div className="card-list-info">
            <div className="card-list-filter">
              <div className="card-list-filter-el">
                <Label for="search-type" className="card-filter-label">Type</Label>
                <Input id="search-type" value={this.state.filter.type ?? ""} type="select" onChange={e => this.setState({filter: Object.assign({}, this.state.filter, {type: e.target.value ? e.target.value : null})})}>
                  <option value="">{ "-" }</option>
                  <option value="breath">{ "Breath" }</option>
                  <option value="charm">{ "Charm" }</option>
                  <option value="structure">{ "Structure" }</option>
                </Input>
              </div>
              <div className="card-list-filter-el">
                <Label for="search-order" className="card-filter-label">Order</Label>
                <Input id="search-order" value={this.state.filter.orderBy ?? ""} type="select" onChange={e => this.setState({filter: Object.assign({}, this.state.filter, {orderBy: e.target.value ? e.target.value : null})})}>
                  <option value="name">{ "Name" }</option>
                  <option value="type">{ "Type" }</option>
                  <option value="energy">{ "Energy" }</option>
                </Input>
              </div>
            </div>
          </div>
        </div>
        <Back to="/play"/>
      </div>
    );*/
  }
}

