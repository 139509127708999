import Fight from './Fight';
import Shop from './Shop';

export default class Room {

	constructor (run, model, reward) {

		this.run = run;
		this.model = model;
		this.reward = reward;
		this.run.notify('newroom', this);
	}

	get type () { return this.model.type; }

	enter () {

		switch (this.type) {
		case "deckbuilder":
			this.run.cardChoice(Math.max(1, this.run.champion.level-2));
			break;
		case "story":
		case "fight":
			this.startFight();
			break;
		case "shop":
			this.startShop();
			break;
		default: break;
		}
	}

	startFight () {

		this.fight = new Fight(this.run);
		this.fight.start(this.model.enemies);
	}

	startShop () {

		this.shop = new Shop(this.run, this.model);
		this.shop.fill();
	}

	cleanup () {

		if (!this.fight && !this.shop)
			return;
		this.run.notify("timedown", this.run);
		this.run.time--;
		let lvup = this.run.champion.checkLevelUp();
		delete this.fight;
		delete this.shop;
		this.run.notify("roomcleanup", this);
		this.run.next();
	}

	serialize () {

		return {
			model: this.model,
			fight: this.fight?.serialize(),
			shop: this.shop?.serialize(),
			reward: this.reward
		}
	}

	static build (run, src) {

		let room = new Room(run, src.model, src.reward);
		if (src.fight)
			room.fight = Fight.build(run, src.fight);
		if (src.shop)
			room.shop = Shop.build(run, src.shop, src.model);
		return room;
	}
}